import { PricedProduct, PricedVariant } from "@medusajs/medusa/dist/types/pricing";
import { useMemo } from "react";

import { useProductPrice } from "@/hook/product/product-price-hook";

export enum PriceType {
  sale = "sale",
}

interface ProductPriceInterface {
  product: PricedProduct;
  variant?: PricedVariant | undefined;
  priceClassName?: string;
}

export const ProductPrice = ({ product, variant }: ProductPriceInterface) => {
  const price = useProductPrice({
    product: product,
    variantId: variant?.id,
  });

  const selectedPrice = useMemo(() => {
    const { getVariantPrice, getCheapestPrice } = price;

    return getVariantPrice || getCheapestPrice() || null;
  }, [price]);

  if (selectedPrice?.price_type === PriceType.sale) {
    return (
      <div className="shadow-[2px_3px_5px_1px_rgba(0,0,0,0.9)] inline-block mt-2 max-w-[100px] sm:max-w-[300px]">
        <div className="py-0 px-7 bg-kodi-yellow">
          <p className="text-lg lg:text-2xl font-bold text-kodi-magenta">-{selectedPrice.percentage_diff}%</p>
        </div>
        <div className="bg-kodi-magenta px-2">
          <p className="text-white font-medium text-end text-sm sm:text-md lg:text-lg">
            <span className="relative before:absolute before:content-[''] before:left-0 before:top-1/2 before:right-0 before:border-t-2 before:border-t-kodi-yellow before:-rotate-12">
              {price.toLocalString(selectedPrice.original_price)}
            </span>{" "}
            *
          </p>
          <p className="text-lg sm:text-2xl lg:text-4xl font-bold text-center text-kodi-yellow">{price.toLocalString(selectedPrice.calculated_price)}</p>
        </div>
      </div>
    );
  }

  return (
    <div className="inline-block mt-2 max-w-32">
      <div className="h-10 flex justify-center items-center bg-kodi-magenta px-2 shadow-[2px_3px_5px_1px_rgba(0,0,0,0.9)]">
        <p className="text-lg sm:text-2xl lg:text-4xl font-bold text-center text-kodi-yellow">{price.toLocalString(selectedPrice?.calculated_price)}</p>
      </div>
    </div>
  );
};

export const ExtrapolatedPrice = ({ selectedVariant, product }: { selectedVariant?: PricedVariant; product: PricedProduct }) => {
  const price = useProductPrice({
    product: product,
    variantId: selectedVariant?.id,
  });

  if (!selectedVariant?.metadata) {
    return <></>;
  }

  const { extrapolated_price, extrapolated_unit } = selectedVariant.metadata;

  if (!extrapolated_price || !extrapolated_unit) {
    return <></>;
  }

  return (
    <p className="ml-2 text-xs font-medium">
      1 {extrapolated_unit as string} = {price.toLocalString(price.calculateExtrapolate(extrapolated_price as number)?.calculated_price)}
    </p>
  );
};
